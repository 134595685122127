<template>
  <div class="formula-box" v-on="$listeners" ref="mathJaxEl">{{formula}}</div>
</template>

<script>

export default {
  props: {
    formula: {
      type: String
    },
    safe: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    formula () {
      setTimeout(() =>{
        this.renderMathJax()
      },1000)
    }
  },
  async mounted () {
    await this.addScript("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-AMS_HTML");
    setTimeout(() =>{
      this.renderMathJax()
    },1000)
  },
  methods: {
    addScript(url) {
      var script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      document.head.appendChild(script);
    },

    renderContent () {
      if (this.safe) {
        this.$refs.mathJaxEl.innerHTML = this.formula
      } else {
        this.$refs.mathJaxEl.innerHTML = this.formula
      }
    },
    renderMathJax () {
      this.renderContent()
      if (window.MathJax) {
        window.MathJax.Hub.Config({
          tex2jax: {
            inlineMath: [['$', '$'], ['\\(', '\\)']],
            displayMath: [['$$', '$$'], ['[', ']']],
            processEscapes: true,
            processEnvironments: true
          },
          // Center justify equations in code and markdown cells. Elsewhere
          // we use CSS to left justify single line equations in code cells.
          displayAlign: 'center',
          'HTML-CSS': {
            styles: { '.MathJax_Display': { margin: 0 } },
            linebreaks: { automatic: true }
          },
          ...this.options
        })
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub,
          this.$refs.mathJaxEl
        ])
      }
    }
  }
}
</script>
<style>
.math-preview-container{
  color:black;
}
</style>
