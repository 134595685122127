<template>
  <div data-app>
    <template>
      <v-container fluid>
        <v-row class="ma-0">
          <v-col class="pb-4 pl-0">
            <span class="text-h5">Tìm kiếm</span>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="6" class="pa-0">
            <v-row class="ma-0">
              <v-col cols="3" class="pa-0">
                <span class="text-body-1">Câu hỏi</span>
              </v-col>
              <v-col cols="9" class="pa-0">
                <hnr-input @active="search" v-model="keyword"></hnr-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="pa-0 ml-3">
            <v-select
                v-model="reply"
                :items="listStatus"
                item-text="name"
                item-value="value"
                dense
                clearable
                label="Trạng thái"
                outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center ma-0">
          <v-col cols="6" class="pa-0">
            <v-btn color="primary" @click="search">Tìm kiếm</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
          :headers="headers"
          :items="asks"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :server-items-length="total"
          @update:items-per-page="updateItemPerPage"
          @update:page="updatePage"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách câu hỏi
              <v-spacer></v-spacer>
            </v-card-title>
          </v-toolbar>
        </template>
        <template v-slot:item.content="{ item }">
          <math-jax-preview :formula="item.content"></math-jax-preview>

        </template>
        <template v-slot:item.act="{ item }">
          <v-switch v-model="item.act" :disabled="true"></v-switch>
        </template>
        <template v-slot:item.reply="{ item }">
          <v-checkbox v-model="item.reply" disabled></v-checkbox>
        </template>
        <template v-slot:item.link="{ item }">
          <v-btn icon @click="openInNew(item)">
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
    </template>

    <!-- DIALOG DELETE  -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Are you sure you want to delete this item?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
          >Cancel
          </v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {validationMixin} from "vuelidate";
import HnrInput from "@/components/elements/hnr-input";
import {SET_LOADING} from "@/store/loading.module";
import {ERROR} from "@/store/alert.module";

import {LIST_ASKS, DELETE_ASK} from "@/store/ask.module";
import MathJaxPreview from "@/components/math/MathJaxPreview";


export default {
  mixins: [validationMixin],
  components: {
    MathJaxPreview,
    HnrInput,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {text: "Nội dung", value: "content", width: "30%"},
      {text: "Slug", value: "slug"},
      {text: "Môn học", value: "subject.name"},
      {text: "Lớp", value: "grade.name"},
      // {text: "Người đăng", value: "user.email"},
      {text: "Đã trả lời", value: "reply"},
      {text: "Link", value: "link"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    listStatus:[
      {value:false, name:'Chưa trả lời'},
      {value:true, name:'Đã trả lời'}
    ],
    reply: null,
    publish_by: "",
    keyword: "",
    category: "",
    startDate: "",
    endDate: "",
    startDialog: "",
    endDialog: "",
    dialog: false,
    dialogDelete: false,
  }),

  computed: {
    ...mapGetters({
      asks: "listAsk",
      total: "totalAsk"
    }),
  },

  mounted() {
    this.getAsks();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Câu hỏi", route: "Câu hỏi"},
    ]);
    this.renderMathJax()
  },
  watch: {
    asks(value) {
      if (value) {
        this.renderMathJax()
      }
    }
  },
  methods: {
    openInNew(item) {
      if (item) {
        window.open(process.env.VUE_APP_FRONT_URL + "/hoi-dap/" + item.slug, '_blank')
      }
    },
    renderMathJax() {
      if (window.MathJax) {
        window.MathJax.Hub.Config({
          tex2jax: {
            inlineMath: [['$', '$'], ['\\(', '\\)']],
            displayMath: [['$$', '$$'], ['[', ']']],
            processEscapes: true,
            processEnvironments: true
          },
          // Center justify equations in code and markdown cells. Elsewhere
          // we use CSS to left justify single line equations in code cells.
          displayAlign: 'center',
          'HTML-CSS': {
            styles: {'.MathJax_Display': {margin: 0}},
            linebreaks: {automatic: true}
          },
          ...this.options
        })
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub,
          this.$refs.mathJaxEl
        ])
      }
    }
    ,
    search() {
      this.getAsks();
    }
    ,
    getAsks() {
      var payload = {
        type: 1,
        search: this.keyword,
        page: this.page,
        per_page: this.itemsPerPage,
        reply: this.reply??null,
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(LIST_ASKS, payload)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },

    updateItemPerPage($event) {
      this.itemsPerPage = $event;
      this.getAsks();
    },

    updatePage($event) {
      this.page = $event;
      this.getAsks();
    },

    editItem(item) {
      let routeData = this.$router.resolve({
        name: "ask-edit",
        params: {slug: item.slug},
      });
      window.open(routeData.href, '_blank');
    },

    deleteItem(item) {
      this.editedIndex = this.asks.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch(DELETE_ASK, this.editedId).then((data) => {
        if (data.status) {
          this.asks.splice(this.editedIndex, 1);
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }

        }
      });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    addNewItem() {
      this.$router.push({name: "ask-detail"});
    }
  },
};
</script>
